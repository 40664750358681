<template>
  <form @submit.prevent="savePriceTier">
    <modal-card title="Add discount">
      <loading v-if="loading" />
      <template v-else>
        <b-message type="is-dark">
          <p>
            <strong>Please note:</strong> the description you enter below will
            show to the user during checkout.
          </p>
        </b-message>
        <b-field label="Description*">
          <b-input v-model="form.description" :disabled="processing" />
        </b-field>

        <b-field label="Percent*">
          <b-input
            :value="form.discount.gbp * -1"
            :disabled="processing"
            :min="1"
            :max="100"
            type="number"
            @input="updatePercentage"
          />
        </b-field>

        <b-field label="Minimum subscriptions">
          <b-input
            :value="form.meta.subscriptions"
            :disabled="processing"
            :min="0"
            expanded
            type="number"
            @input="form.meta.subscriptions = parseInt($event)"
          />
        </b-field>
      </template>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!isValid || processing"
        type="submit"
        class="button is-success"
      >
        {{ exists ? "Update" : "Add" }}
      </button>
    </modal-card>
  </form>
</template>

<script>
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc
} from "firebase/firestore";
const _ = require("lodash");
const currencies = require("@/data/currencies.json");
export default {
  name: "AddPriceTier",
  props: {
    priceTierId: { type: String, required: false, default: null },
    userId: { type: String, required: true }
  },
  data() {
    return {
      loading: false,
      exists: false,
      processing: false,
      form: {
        description: "",
        discount: _.mapValues(currencies, () => 0),
        group: `user-${this.userId}`,
        meta: {
          subscriptions: 0
        },
        type: "percent"
      }
    };
  },
  computed: {
    isValid() {
      if (!this.form.description) return false;
      if (!this.$_.isInteger(this.form.meta.subscriptions)) return false;
      this.$_.each(currencies, (currency, key) => {
        if (!this.$_.isInteger(this.form.discount[key])) return false;
        if (this.form.discount[key] >= 0) return false;
      });
      return true;
    }
  },
  mounted() {
    this.getPriceTiers();
  },
  methods: {
    updatePercentage(value) {
      value = parseInt(value) * -1;
      this.$_.each(currencies, (currency, key) => {
        this.form.discount[key] = value;
      });
    },
    getPriceTiers() {
      if (!this.priceTierId) return;
      this.loading = true;
      const ref = doc(this.$firestore, "priceTiers", this.priceTierId);
      getDoc(ref)
        .then(priceTier => {
          if (priceTier.exists()) {
            this.exists = true;
            this.form = priceTier.data();
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async savePriceTier() {
      if (!this.isValid) return;
      this.processing = true;
      let form = this.form;
      form.meta.subscriptions = parseInt(this.form.meta.subscriptions);
      form.type = "percent";
      //if(form.type !== 'percent') form = this.$_.omit(form, ['type']);
      const userRef = doc(this.$firestore, "users", this.userId);
      await updateDoc(userRef, {
        priceTierGroup: form.group,
        dateUpdated: serverTimestamp()
      });

      let ref = doc(collection(this.$firestore, "priceTiers"));
      if (this.priceTierId) {
        ref = doc(this.$firestore, "priceTiers", this.priceTierId);
      }

      setDoc(ref, form)
        .then(() => {
          this.processing = false;
          this.$emit("sucess");
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: `Error saving discount`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
